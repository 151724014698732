.hero-img img{
    width:100%;
    height: 100%;
    object-fit:cover;
    border-radius: 10px;
    /* border: 1px solid red; */
}

.slider {
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 2px 10px 0.7px rgb(247, 247, 247);
    height:300px;
    width:850px;
    z-index: 10;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider img{
    height:300px;
    width:850px;
   object-fit: contain;
}
.swiper-button-next{
    color: rgb(72, 72, 72) !important;
    opacity: 1 !important;
}
.swiper-button-prev{
    color: rgb(73, 73, 73) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet{
    width: 20px !important;
    height: 20px !important;
    background-color: rgb(173, 173, 173) !important;
}


@media screen and (max-width:950px){
    .slider {
        height:300px;
        width:750px;
        z-index: 10;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


@media screen and (max-width:850px){
    .slider {
        height:250px;
        width:550px;
        z-index: 10;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider img{
        height:100%;
        width:100%;
       object-fit: contain;
    }
}
@media screen and (max-width:540px){
    .slider {
        height:200px;
        width:300px;
        z-index: 10;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider img{
        height:100%;
        width:100%;
       object-fit: contain;
    }
}



/* .swiper-pagination-bullet{
    width: 50px !important;
    height: 50px !important;
}
.swiper-pagination-bullet:nth-child(1)
{
    background-color: rgb(43, 218, 210) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet:nth-child(2)
{
    background-color: rgb(218, 43, 43) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet:nth-child(3)
{
    background-color: rgb(69, 69, 69) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet:nth-child(4)
{
    background-color: rgb(43, 116, 218) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    background-color: rgb(255, 85, 85) !important;
    border: 5px solid rgb(42, 42, 42);
} */