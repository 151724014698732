.main-main{
    width: 100%;
    height: 100%;
}
.hero{
    max-width: 100%;
}
.arrive{
    margin-top: 50px;
    width: 100%;
}
.arrive h2{
    color: var(--font-2);
    text-transform: uppercase;
    font-size:30px;
}
.new{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    margin-top: 25px;
    align-content: center;
}
@media screen and (max-width:850px){
    .new{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;
        margin-top: 0px;
    }
}

@media screen and (max-width:540px){
    .new{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 25px;
        margin-top: 0px;
    }
}

