.big{
    width: 100%;
}
.big-hero{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:0px;
}
.b-left{
    /* border: 1px solid goldenrod; */
    width: auto;
    overflow: hidden;
}
.b-left .b-img{
    /* border: 1px solid rgb(32, 85, 218);  */
    width:100%;
}
.b-left .b-color-btn{
    display: flex;
    gap: 10px;
    margin-top:25px;
    margin-left: 75px;
}
.b-left .b-color-btn > div{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    cursor: pointer;
}
.b-left .b-color-btn :nth-child(1){
    background: rgb(66, 63, 97);
}
.b-left .b-color-btn :nth-child(2){
    background: rgb(215, 82, 82);
}
.b-left .b-color-btn :nth-child(3){
    background: rgb(49, 74, 57);
}
.b-left .b-color-btn :nth-child(4){
    background: rgb(35, 35, 41);
}

.b-right h1{
    color: var( --font-2);
    font-size: 50px;
}
.b-right p{
    color: var( --font-color);
    margin-top: 25px;
    width: 75%;
    font-size: 18px;
}
.b-right p h5{
    color: rgb(137, 208, 255);
}
.model h5{
    font-size: 25px;
    color: var( --font-2);
    margin-top: 25px;
}
.mod{
    display: flex;
    gap: 10px;
    width: 100%;
    margin-top: 25px;
    text-align: center;
}
.mod h4{
    font-weight: bold;
    color: var(--font-2);
}
.mod .m-left,.m-right{
    /* border: 3px solid rgb(91, 91, 91); */
    width: 150px;
    text-align: center;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.mod .m-left p{
    margin-top: 0;
    text-align: center;
    width: 100%;
    color: var(--font-color);
    font-size: 13px;
}
.mod .m-left .price{
    margin-top: 20px;
}
.mod .m-right .price{
    margin-top: 20px;
}
.mod .m-right p{
    margin-top: 0;
    text-align: center;
    width: 100%;
    color: var(--font-color);
    font-size: 13px;
}
.b-right .cart{
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.b-right .cart button{
    cursor: pointer;
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    outline: none;
    border: none;
    background: rgb(161, 123, 237);
    color: #fff;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
}
.b-right .cart  .fa-heart{
    color: gold;
    font-size: 25px;
}
.p-description{
    margin-top: 50px;
}
.p-description >div{
    color: var( --font-2);
    font-size: 18px;
}
.p-description p{
    margin-top: 35px;
    width:80%;
    font-size: 18px;
    word-spacing: 5px;
    color: var( --font-2);
}

.hr{
    margin-top: 50px;
    width: 100%;
    height: 2px;
    background:  var(--font-color);
}

.big-p-section{
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 25px;
}

/* 

.mod .m-left{
    border: 3px solid rgb(91, 91, 91);
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    border-radius: 10px;
}
.mod .m-right{
    border: 3px solid rgb(91, 91, 91);
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    border-radius: 10px;
} */
/* input[type=radio]:checked + label{
    border-color: black;
} */
@media screen and (max-width:950px) {
    .big-hero{
        grid-template-columns: 1fr ;
    }
    .b-right h1{
        font-size: 35px;
    }
    .b-right p{
        width: 100%;
        font-size: 16px;
    }
    .model h5{
        font-size: 22px;
    }
    .b-right .cart button{
        padding: 10px 10px 10px 10px;
        font-size: 15px;
    }


    .p-description >div{
        color: var( --font-2);
        font-size: 16px;
    }
    .p-description p{
        width:100%;
        font-size: 16px;
        word-spacing: 2px;
    }
    .big-p-section{
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr ;
        grid-gap: 50px;
    }
    
}

@media screen and (max-width:850px) {
    .big-hero{
        grid-template-columns: 1fr;
    }
    .b-right h1{
        font-size: 35px;
    }
    .b-right p{
        width: 100%;
        font-size: 16px;
    }
    .model h5{
        font-size: 22px;
    }
    .b-right .cart button{
        padding: 10px 10px 10px 10px;
        font-size: 15px;
    }


    .p-description >div{
        color: var( --font-2);
        font-size: 16px;
    }
    .p-description p{
        width:100%;
        font-size: 16px;
        word-spacing: 2px;
    }
    .big-p-section{
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 25px;
    }
    
}


@media screen and (max-width:540px) {
    .big-hero{
        grid-template-columns: 1fr;
    }
    .b-right h1{
        font-size: 25px;
    }
    .b-right p{
        width: 100%;
        font-size: 16px;
    }
    .model h5{
        font-size: 22px;
    }
    .b-right .cart button{
        padding: 10px 10px 10px 10px;
        font-size: 15px;
    }


    .p-description >div{
        color: var( --font-2);
        font-size: 16px;
    }
    .p-description p{
        width:100%;
        font-size: 16px;
        word-spacing: 2px;
    }
    .big-p-section{
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 25px;
    }
    
}